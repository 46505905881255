/* THIS FILE IS AUTO-GENERATED - DO NOT MAKE CHANGES TO THIS FILE DIRECTLY */

.icon--burger {
    width: 20px;
    height: 12px;
}

.icon--chevron-left {
    width: 10.5px;
    height: 16.2px;
}

.icon--chevron-right {
    width: 10.5px;
    height: 16.2px;
}

.icon--ellipses {
    width: 24px;
    height: 6px;
}

.icon--logo {
    width: 122.1px;
    height: 22.7px;
}

.icon--logo-50th {
    width: 124px;
    height: 24px;
}

.icon--logo-50th-white {
    width: 124px;
    height: 24px;
}

.icon--logo-pro {
    width: 152.8px;
    height: 35px;
}

.icon--logo-pro-white {
    width: 152.8px;
    height: 35px;
}

.icon--logo-white {
    width: 122.1px;
    height: 22.7px;
}

.icon--navigation-primary--cart {
    width: 24px;
    height: 24px;
}

.icon--navigation-primary--hamburger {
    width: 40px;
    height: 40px;
}

.icon--navigation-primary--profile {
    width: 16px;
    height: 16px;
}

.icon--navigation-primary--search {
    width: 24px;
    height: 24px;
}

.icon--navigation-primary--wishlist {
    width: 14px;
    height: 13px;
}

.icon--navigation-primary--x {
    width: 40px;
    height: 40px;
}

.icon--patagonia-loading {
    width: 262px;
    height: 50px;
}

.icon--play {
    width: 18.1px;
    height: 20px;
}

.icon--search {
    width: 15px;
    height: 15px;
}

.icon--shopping-bag {
    width: 20px;
    height: 24px;
}

.icon--shopping-bag-filled {
    width: 20px;
    height: 24px;
}

.icon--x {
    width: 16.1px;
    height: 16.1px;
}
